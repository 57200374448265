<template>
  <div>

    <div class="contentFrame">
      <div class="leftSide">
        
        
      <div class="subinfotext">会社概要</div>
      
   <div class="sentent_g1">
      <table width="100%" border="0" cellpadding="0" cellspacing="1" class="formBox">
        <tbody>
          <tr>
            <td colspan="2" class="formListTh4">【サンタクロースの手紙、販売管理】</td>
          </tr>
          <tr>
            <td class="formListTh3">■販売者名</td>
            <td class="formListTdw">株式会社スカットコミュニケーションズ</td>
          </tr>
          <tr>
            <td class="formListTh3">■責任者</td>
            <td class="formListTdw">代表取締役 木村 安晴</td>
          </tr>
          <tr>
            <td class="formListTh3">■郵便番号</td>
            <td class="formListTdw">〒325-0303</td>
          </tr>
          <tr>
            <td class="formListTh3">■所在地</td>
            <td class="formListTdw">栃木県那須郡那須町高久乙3584-4</td>
          </tr>
          <tr>
            <td class="formListTh3">■電話番号</td>
            <td class="formListTdw">0287-74-6503</td>
          </tr>
          <!--<tr>
            <td class="formListTh3">■FAX番号</td>
            <td class="formListTdw">0287-74-6506</td>
            <td class="formListTdw">0287-69-6070</td>
          </tr>-->
        </tbody>
      </table>

   </div>
      

      </div>
      <div class="rightSide">
        <sideContents />
      </div>
    </div>
  </div>

</template>

<script>
export default  {
    created: function() { this.$parent.middle = 2; }
    }
</script>

<style scoped lang="scss">


$pc: 1024px; // PC
$tab: 680px; // タブレット
$sp: 480px; // スマホ
@mixin pc {
    @media (max-width: ($pc)) {
        @content;
    }
}

@mixin tab {
    @media (max-width: ($tab)) {
        @content;
    }
}

@mixin sp {
    @media (max-width: ($sp)) {
        @content;
    }
}

.subinfotext{
  color: #454552;
  font-size: 1.5em;
  font-weight: bold;
  height: 2.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url("../../assets/images/ilst1.png") no-repeat left center / contain;
  @include sp{
    padding-left: 10vw;
    font-size: 1em;
  }
}
.sentent_r1{
  color: #D72E23;
  line-height: 1.7;
  margin: 2em 0;
}
.sentent_g1{
  text-align: left;
  color:#454552;
  line-height: 1.7;
  margin: 2em 0;
}
.subsent2{
  margin-bottom: 2em;
  text-align: left;
  border:solid 3px #D72E23;
  padding: 1em;
  h3{
    color: #D72E23;
    padding: 2em 0;
  }
  h4{
    color: #D72E23;
    margin : 1em 0;
    padding-left: 1em;
    border-left: double 10px #d72e23;
  }
}
.centeimg1{
  padding: 1em 0;
  text-align: center;
  img{
  width: 33%;
    max-width: 100%;
  }
}
.centeimg2{
  padding: 1em 0;
  text-align: center;
  img{
  width: 90%;
    max-width: 100%;
  }
}
</style>